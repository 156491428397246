@use "../../assets/sass/variables" as *;

.main {
  background-image: linear-gradient(
    to bottom right,
    $color-primary,
    $text-primary,
    $text-primary,
    $color-primary
  );
  padding: 1rem;
  min-height: 85vh;
}
