@use "../../assets/sass/variables" as *;

.navbar {
  z-index: 1;
  position: fixed;
  background-color: whitesmoke;
  box-shadow: inset 1px 1px 10px $card-secondary, 0 1px 2px $card-secondary;
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.4rem;
  align-items: center;

  .heading {
    font-family: $primary-font;
    height: 100%;
    font-size: 1.8rem;
    writing-mode: vertical-lr;
    text-align: center;
    &--right {
      transform: rotate(180deg);
    }
  }
}
