@use "../../assets/sass/variables" as *;

.card {
  padding: 1rem;
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &__heading {
    font-family: $primary-font;
  }

  &__image {
    @include img-style;
  }

  &__hidden {
    position: absolute;
    padding: 1rem;
    display: none;
    color: white;
    width: 200px;
    height: 300px;
    background-color: rgb(0, 0, 0, 0.7);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    .hidden__heading {
      font-family: $primary-font;
      font-size: 1rem;
    }
  }

  &:hover > &__hidden {
    display: flex;
  }
}
