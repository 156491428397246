* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  display: grid;

  &__nav {
    height: 15vh;
  }
}
